@font-face {
  font-family: "HM Slussen Expanded";
  src: url("../fonts/HM-Slussen-Expanded-Bold.otf");
}

@font-face {
  font-family: "HM Slussen Condensed";
  src: url("../fonts/HM-Slussen-Condensed-Regular.otf");
}
@font-face {
  font-family: "HM Slussen";
  src: url("../fonts/HM-Slussen-Regular.otf");
}
@font-face {
  font-family: "HM Group Ampersand";
  src: url("../fonts/HMGroupAmpersand-Regular.otf");
}
@font-face {
  font-family: "HMSansBold";
  src: url("../fonts/HMSansBold.otf");
}

@font-face {
  font-family: "HM Sans";
  src: url("../fonts/HMSansRegular.otf");
}

@font-face {
  font-family: "HMSansSemiBold";
  src: url("../fonts/HMSansSemiBold.otf");
}

@font-face {
  font-family: "HMSansLight";
  src: url("../fonts/HMSansLight.otf");
}

// If you want to override variables do it here
@import "variables";

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

@import "layout";
@import "example";

@import "header";

// If you want to add custom CSS you can put it here.
@import "custom";

.op-sprite-svg {
  background-image: url("../images/operationAnalyticsSprite.svg");
}
.backButtonSvg {
  vertical-align: middle;
  background-position: -76px -95px;
  width: 30px;
  height: 30px;
}
html body {
  height: 100%;
  // font-family: "HMSansRegular", sans-serif;
  font-family: "HM Slussen", sans-serif;
  scroll-behavior: smooth;
}
.enableCursor {
  cursor: pointer;
}
